@use '../../styleguide/vars.scss';
@use '../../styleguide/colors.scss';
@use '../../styleguide/mixins.scss';

@keyframes slideLeft {
  0% { opacity: 0; transform: translateX(150px);}
  100% { opacity: 1; transform: translateX(0px);}
}

.root {
  position: fixed;
  bottom: vars.$nodeSpacing;
  right: 0;
  display: grid;
  gap: vars.$gutter;
  z-index: 1002;
  animation-name: slideLeft;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  width: 100%;

  @media screen and (min-width: vars.$medium) {
    width: auto;
  }

  @media screen and (min-width: vars.$large) {
    bottom: initial;
    top: vars.$gutter;
  }

  .message {
    margin-bottom: 15px;
  }

  .headline {
    display: grid;
    grid-template-columns: auto min-content;
    gap: vars.$nodeSpacing;
  }
}

.notification {
  display: grid;
  position: relative;
  max-width: 400px;
  background: white;
  padding: vars.$nodeSpacing;
  margin: 0 vars.$nodeSpacing;
  gap: vars.$nodeSpacing;
  border-radius: 4px;
  @include mixins.dropShadowBold();

  header {
    font-weight: bold;
    font-stretch: condensed;
  }

  &::after {
    content: "";
    overflow: hidden;
    display: block;
    bottom: 0;
    height: 2px;
    width: 100%;
    position: absolute;
  }
  &.error {
    &::after {
      background: colors.$error;
    }
  }
  
  &.success {
    &::after {
        background: colors.$succes;
    }
  }
}

.closeBtn {
  color: colors.$secondary-text;
}

